import "styles/pages/service.scss"

import React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import PageHeader from "components/PageHeader/PageHeader"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"
import Button from "components/Button/Button"

import ServiceIco1 from "assets/images/service-ico-1.svg"
import ServiceIco2 from "assets/images/service-ico-2.svg"
import ServiceIco3 from "assets/images/service-ico-3.svg"
import ServiceIco4 from "assets/images/service-ico-4.svg"
import ServiceIco5 from "assets/images/service-ico-5.svg"
import ServiceIco6 from "assets/images/service-ico-6.svg"

const Service = () => {
  const title = "Serwis"

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />

      <section className="service">
        <div className="container-fluid">
          <PageHeader title={title} />

          <div className="row">
            <div className="col-lg-4">
              <PageSubHeader title="Informacje ogólne" />
              <p>
                Serwis firmy MEMTECH zajmuję się kompleksową naprawą notebooków,
                stacji roboczych i komputerów stacjonarnych. Zakres naszych
                działań obejmuje pełną diagnostykę urządzeń zaczynając od
                prostych zmian w oprogramowaniu, wymianę kompletnych elementów,
                aż po zaawansowane naprawy poszczególnych podzespołów. Posiadamy
                profesjonalne urządzenia i narzędzia do diagnostyki i
                wykonywania szczegółowych napraw. Nasze wieloletnie
                doświadczenie sprawia, iż nasze usługi są wykonywane precyzyjnie
                i dokładnie.
              </p>
              <p>
                W firmie MEMTECH mają Państwo pewność indywidualnego podejścia
                do każdego zadania. Fachowe porady, zawsze darmowa diagnoza i
                gwarancja na wykonaną naprawę to coś co nas wyróżnia.
              </p>
            </div>
            <div className="col-lg-7 offset-lg-1">
              <PageSubHeader title="Zakres usług" />
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="service__ico">
                    <div className="service__ico-image">
                      <img src={ServiceIco1} alt="" />
                    </div>
                    Naprawa matrycy
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="service__ico">
                    <div className="service__ico-image">
                      <img src={ServiceIco2} alt="" />
                    </div>
                    Czyszczenie elektroniki
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="service__ico">
                    <div className="service__ico-image">
                      <img src={ServiceIco3} alt="" />
                    </div>
                    Naprawa systemów operacyjnych
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="service__ico">
                    <div className="service__ico-image">
                      <img src={ServiceIco4} alt="" />
                    </div>
                    Diagnostyka
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="service__ico">
                    <div className="service__ico-image">
                      <img src={ServiceIco5} alt="" />
                    </div>
                    Wymiana baterii
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="service__ico">
                    <div className="service__ico-image">
                      <img src={ServiceIco6} alt="" />
                    </div>
                    Instalacja oprogramowania
                  </div>
                </div>
              </div>

              <PageSubHeader title="Nie znalazłeś usługi?" />
              <p>
                Jeżeli nie znalazłeś na powyższej liście usługi serwisowej
                której potrzebujesz, nie wszystko stracone. Prosimy o kontakt w
                celu określenia problemu do rozwiązania.
              </p>
              <Button type="link" to="/kontakt/" className="button--bordered">
                Kontakt
              </Button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Service
